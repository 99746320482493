<template>
  <div class="body1" v-if="!initLoading">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'booking_list'}">{{ $t('booking_list') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('booking_detail') }}({{ orderInfo.order_id }})
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="order-detail" v-if="orderInfo.HasTicket">
      <table>
        <thead>
          <tr>
            <th>
              {{ $t('work_order_log')}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ticket-log :order_id="orderInfo.order_id"></ticket-log>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail">
      <table>
        <thead>
          <tr>
            <th colspan="4">
              <a-row>
                <a-col :span="6">
                  <span style="margin-right:20px">{{ orderInfo.order_id }}</span>
                  <order-hotel-status-tag :order-status="orderInfo.order_status"/>
                </a-col>
                <a-col :span="12" style="text-align: center">
                  <span class="error-color" v-if="orderInfo.last_pay_time">
                    {{ $t('latest_payment_time')}}: {{ dayjs.unix(orderInfo.last_pay_time).format('YYYY-MM-DD HH:mm:ss') }}
                  </span>
                </a-col>
                <a-col :span="6" style="text-align: right">
                  <template v-if="EExtraData && EOccPricing">
                    <template v-if="orderInfo.order_status === OrderHotelStatus.Success">
                      <a-popover placement="bottomRight" trigger="click">
                        <template #content>
                          <e-rate-detail :check-in="dayjs.unix(orderInfo.checkin).format('YYYY-MM-DD')" :room-count="orderInfo.PaxRoom.Count" :e-occ-pricing="EOccPricing" :currency="orderInfo.curcode" :total-price="orderInfo.price" :is-order-detail="true"></e-rate-detail>
                        </template>
                        <template #title>
                          <span>{{ $ta('price|detail') }}</span>
                        </template>
                        <div style="cursor: pointer;">
                          <strong class="warning-color"><QuestionCircleFilled /> {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}</strong>
                        </div>
                      </a-popover>
                    </template>
                    <template v-else-if="orderInfo.order_status === OrderHotelStatus.Canceled">
                      <strong class="warning-color">{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.cancelled_fee, 2) }}</strong>&nbsp;
                      <a-tooltip :color="'#ffffff'" placement="topRight">
                        <template #title>
                          <div style="color: #000000;" v-if="orderInfo.cancelled_fee === 0">{{ $t('cancelled_free_remark') }}</div>
                          <div style="color: #000000;" v-else-if="orderInfo.cancelled_fee > 0">{{ $t('cancelled_charge_remark', { pri: orderInfo.curcode + ' ' + $filters.formatAmount(orderInfo.cancelled_fee, 2)}) }}</div>
                          <div style="color: #000000;" v-else>{{ $t('cancelled_unknow_remark') }}</div>
                        </template>
                        <QuestionCircleFilled class="warning-color" />
                      </a-tooltip>
                    </template>
                    <template v-else>
                      <strong class="warning-color">{{ orderInfo.curcode }} 0</strong>
                    </template>
                  </template>
                  <template v-else>
                    {{ $t('total_price')}}:
                    <template v-if="orderInfo.order_status === OrderHotelStatus.Fail">
                      <strong class="warning-color">{{ orderInfo.curcode }} 0</strong>
                    </template>
                    <template v-else-if="orderInfo.order_status === OrderHotelStatus.Canceled">
                      <strong class="warning-color">{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.cancelled_fee, 0) }}</strong>&nbsp;
                      <a-tooltip :color="'#ffffff'" placement="topRight">
                        <template #title>
                          <div style="color: #000000;" v-if="orderInfo.cancelled_fee === 0">{{ $t('cancelled_free_remark') }}</div>
                          <div style="color: #000000;" v-else-if="orderInfo.cancelled_fee > 0">{{ $t('cancelled_charge_remark', { pri: orderInfo.curcode + ' ' + $filters.formatAmount(orderInfo.cancelled_fee, 0)}) }}</div>
                          <div style="color: #000000;" v-else>{{ $t('cancelled_unknow_remark') }}</div>
                        </template>
                        <QuestionCircleFilled class="warning-color" />
                      </a-tooltip>
                    </template>
                    <template v-else>
                      <strong class="warning-color">{{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}</strong>
                    </template>
                  </template>
                </a-col>
              </a-row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="label">{{$t('checkin_date')}} ~ {{$t('checkout_date')}}</div>
              {{ dayjs.unix(orderInfo.checkin).format(checkInOutFormat) }} ~ {{ dayjs.unix(orderInfo.checkout).format(checkInOutFormat) }}
               ({{ $t('night_unit', {'n': dayjs.unix(orderInfo.checkout).diff(dayjs.unix(orderInfo.checkin), 'days')}) }})
            </td>
            <td>
              <div class="label">{{$t('room_type')}}</div>
              <template v-if="orderInfo.room_id > 0">
                {{ isZhCn ? getNameCnEn(orderInfo.room_name_cn, orderInfo.room_name_en) : orderInfo.room_name_en }}
              </template>
              <template v-else>
                {{ orderInfo.room_name }}
              </template>
              ({{ $t('room_unit', {n: orderInfo.PaxRoom.Count}) }})
            </td>
            <td>
              <div class="label">{{$t('meal_type')}}</div>
              {{ $t('mealtype_unit.' + orderInfo.board_code, {n: orderInfo.PaxRoom.Count * orderInfo.PaxRoom.Adults}) }}
            </td>
            <td rowspan="2" style="text-align: center">
              <a-space direction="vertical">
                <a-button type="primary" size="small" block v-if="(orderInfo.order_status === OrderHotelStatus.Success && orderInfo.pay_way < 0) || orderInfo.order_status === OrderHotelStatus.Unpaid"><router-link :to="{name: 'booking_pay', params: {OrderId: orderId}}">{{$t('pay')}}</router-link></a-button>
                <template v-if="orderInfo.order_status === OrderHotelStatus.Success">
                  <a-button type="primary" size="small" danger block v-if="orderInfo.checkout > dayjs().unix()" @click="showCancelFee" :loading="cancelLoading">{{ $t('cancel_booking') }}</a-button>
                  <template v-if="EExtraData">
                    <a-button size="small" block><router-link :to="{name: 'booking_voucher_eps', params: {OrderId: orderId}}" target="_blank">{{ $t('voucher') }}</router-link></a-button>
                  </template>
                  <template v-else>
                    <a-button size="small" block><router-link :to="{name: 'booking_voucher', params: {OrderId: orderId}, query: { Type: 'letter' }}" target="_blank">{{ $t('confirmation_letter') }}</router-link></a-button>
                    <a-button size="small" block><router-link :to="{name: 'booking_voucher', params: {OrderId: orderId}, query: { Type: 'voucher' }}" target="_blank">{{ $t('voucher') }}</router-link></a-button>
                  </template>
                </template>
              </a-space>
            </td>
          </tr>
        <tr>
          <td>
            <div class="label">{{$t('payment_method')}}</div>
            <template v-if="!isEmpty(orderInfo.pay_way)">{{ $t('payment_way.' + orderInfo.pay_way) }}</template>
            <template v-else>{{ getEmptyValue(orderInfo.payment_way)}}</template>
          </td>
          <td>
            <div class="label">{{$t('client_ref_id')}}</div>
            {{ getEmptyValue(orderInfo.source_id == orderInfo.order_id ? '': orderInfo.source_id, isZhCn) }}
          </td>
          <td>
            <div class="label">{{$t('group_booking_id')}}</div>
            {{ getEmptyValue(orderInfo.source_id1, isZhCn) }}
          </td>
        </tr>
        <tr>
          <td>
            <div class="label">{{$t('contact_person')}}</div>
            {{ orderInfo.contact_name }}
          </td>
          <td>
            <div class="label">Email</div>
            {{ orderInfo.contact_email }}
          </td>
          <td>
            <div class="label">{{$t('contact_tel')}}</div>
            {{ orderInfo.contact_phone }}
          </td>
          <td>
            <div class="label">{{$t('booking_time')}}</div>
            {{ dayjs.unix(orderInfo.order_date).format('YYYY-MM-DD HH:mm:ss') }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail" v-if="EExtraData?.bed_group_id">
      <table>
        <thead>
          <tr>
            <th colspan="4">
              {{ $t('select_bedtype') }}
              <span class="error-color f13">
                {{ $t('select_bedtype_warning') }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ EExtraData.ERateInfo?.bed_groups[EExtraData.bed_group_id].description }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail">
      <table>
        <thead>
          <tr>
            <th colspan="3">
              <strong><router-link :to="{name:'hotel_detail', params:{HotelId: orderInfo.hotel_id}}" target="_blank">
                <template v-if="isZhCn">
                  {{
                    isEmpty(orderInfo.hotel_name_cn) ? orderInfo.hotel_name_en : (orderInfo.hotel_name_cn + ' (' + orderInfo.hotel_name_en + ')')
                  }}
                </template>
                <template v-else>
                  {{ orderInfo.hotel_name_en }}
                </template>
              </router-link></strong>
              <span style="margin-left: 20px;">
                <StarFilled class="star-color1" v-for="i in parseInt(orderInfo.hotel_star)" v-bind:key="i"/>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="label">{{$t('city')}}</div>
              <template v-if="isZhCn">
                {{ getNameCn(orderInfo.city_name_cn, orderInfo.city_name_en)}}, {{ getNameCn(orderInfo.country_name_cn, orderInfo.country_name)}}
              </template>
              <template v-else>
                {{ orderInfo.city_name_en }}, {{ orderInfo.country_name }}
              </template>
            </td>
            <td>
              <div class="label">{{$t('address')}}</div>
              <template v-if="isZhCn">
                {{ isEmpty(orderInfo.hotel_address_cn) ? orderInfo.hotel_address_en : orderInfo.hotel_address_cn }}
              </template>
              <template v-else>
                {{ orderInfo.hotel_address_en }}
              </template>
            </td>
            <td class="success-color">
              <div class="label">{{$t('hotel_confirm_no')}}</div>
              {{ getEmptyValue(orderInfo.confirm_no,isZhCn) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail">
      <table>
        <thead>
        <tr>
          <th colspan="4">
            <strong>{{ $t('occupancy_info') }}</strong>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <div class="label">{{$t('nationality')}} / {{ $t('residency') }}</div>
            {{ getCountryName(orderInfo.PaxRoom.Nationality) }}
          </td>
          <td>
            <div class="label">{{$t('per_room')}}</div>
            {{ $t('adult_unit', {'n': orderInfo.PaxRoom.Adults}) }},
            {{ $t('child_unit', {'n': orderInfo.PaxRoom?.Children?.length}) }}
            <template v-if="orderInfo.PaxRoom?.Children?.length > 0">
              (<template v-for="(age,index) in orderInfo.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old', {n: age}) }}</template>)
            </template>
          </td>
          <td>
            <div class="label">{{$t('guest_name')}}</div>
            <template v-for="(name,nameIndex) in orderInfo.PaxRoom.PaxNames" v-bind:key="nameIndex">
              {{name.LastName}} {{name.FirstName}};
            </template>
          </td>
          <td>
            <div class="label">{{$t('special_request')}}</div>
            {{ getEmptyValue(orderInfo.special, isZhCn) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail" v-if="EExtraData">
      <table>
        <thead>
          <tr>
            <th colspan="2">
              <strong>{{ $t('cancel_policy') }}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
            <template v-if="!EExtraData.ERateInfo?.refundable">
              <tr><td class="error-color" colspan="2" style="font-weight: bold;">{{ $t('non_refundable_remark') }}</td></tr>
            </template>
            <template v-else-if="isEpsPartiallyRefundable(dayjs, EExtraData.ERateInfo)">
              <table class="border order_fill_table">
                <tbody>
                <tr>
                  <td>
                    <div>{{ $t('cancel_policy_info_3', { f: getECancelText(EExtraData.ERateInfo.cancel_penalties[0], $t, orderInfo.PaxRoom.Count) }) }}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              <tr>
                <td colspan="2">
                  {{ $t('free_cancel_before', { str: dayjs(EExtraData.ERateInfo.cancel_penalties[0].start).format('YYYY-MM-DD HH:mm') }) }};
                </td>
              </tr>
              <tr v-for="(item,index) in EExtraData.ERateInfo.cancel_penalties" v-bind:key="index">
                <td colspan="2">
                  {{ $t('cancel_policy_info_1', { s: dayjs(item.start).format('YYYY-MM-DD HH:mm'), e: dayjs(item.end).format('YYYY-MM-DD HH:mm'), f: getECancelText(item, $t, orderInfo.PaxRoom.Count) }) }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                {{ $t('cancel_policy_info_2', { e: dayjs(EExtraData.ERateInfo.cancel_penalties[EExtraData.ERateInfo.cancel_penalties.length - 1].end).format('YYYY-MM-DD HH:mm') }) }} ({{ $t('beijing_time_remark') }})
                </td>
              </tr>
            </template>
        </tbody>
      </table>
    </div>
    <div class="order-detail" v-else>
      <table>
        <thead>
          <tr>
            <th colspan="2">
              <strong>{{ $t('cancel_policy') }}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <template v-if="!isRefundable(orderInfo.cancel_hour, dayjs.unix(orderInfo.checkin), dayjs.unix(orderInfo.order_date))">
              <td class="error-color" colspan="2" style="font-weight: bold;">{{ $t('non_refundable_remark') }}</td>
            </template>
            <template v-else>
              <td>
                <div class="label">{{$t('date')}}</div>
                {{ $t('from', {'str' : getCancelDatetime(dayjs.unix(orderInfo.checkin), orderInfo.cancel_hour)}) }}
              </td>
              <td>
                <div class="label">{{$t('cancellation_fee')}}</div>
                {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail order-detail1" v-if="EExtraData?.AirTicketInfo">
      <table>
        <thead>
        <tr>
          <th colspan="6">
            <strong>{{ $t('air_ticket_info') }}</strong>
          </th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="label">{{ $t('flight_no') }}</div>
              {{EExtraData.AirTicketInfo.FlightNo}}
            </td>
            <td>
              <div class="label">{{ $t('air_ticket_no') }}</div>
              {{EExtraData.AirTicketInfo.TicketNo}}
            </td>
            <td>
              <div class="label">{{ $t('date') }}</div>
              {{EExtraData.AirTicketInfo.Date}}
            </td>
            <td>
              <div class="label">{{ $t('air_departure') }}</div>
              {{EExtraData.AirTicketInfo.Departure}}
            </td>
            <td>
              <div class="label">{{ $t('air_landing') }}</div>
              {{EExtraData.AirTicketInfo.Landing}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail" v-if="!isEmpty(orderInfo.remark)">
      <table>
        <thead>
        <tr>
          <th>
            <strong>{{ $t('rate_remark') }}</strong>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-html="orderInfo.remark">
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="order-detail" v-if="orderInfo.hotelPolicy">
      <table>
        <thead>
          <tr>
            <th>
              <strong>{{ $t('hotel_policy') }}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="hotel-policy-content1">
                <div><strong>{{ $ta('checkin|and|checkout') }}</strong></div>
                <span>{{ $t('hanlde_checkin_time') }}: <strong>{{ orderInfo.hotelPolicy.begin_time }} - {{ orderInfo.hotelPolicy.end_time }}</strong></span>
                <span>{{ $t('checkout_time') }}: <strong>{{ orderInfo.hotelPolicy.checkout_time }}</strong></span>
                <span v-if="orderInfo.hotelPolicy.min_age" >{{ $t('checkin_min_age', {n: orderInfo.hotelPolicy.min_age}) }}</span>
              </div>
              <div class="hotel-policy-content1" v-if="orderInfo.hotelPolicy.special_instructions">
                <div><strong>{{ $t('special_check_in_instructions') }}</strong></div>
                <div class="ti28" v-html="orderInfo.hotelPolicy.special_instructions"></div>
              </div>
              <div class="hotel-policy-content1" v-if="orderInfo.hotelPolicy.instructions">
                <div><strong>{{ $t('check_in_instructions') }}</strong></div>
                <div class="ex-content2" v-html="orderInfo.hotelPolicy.instructions">
                </div>
              </div>
              <div class="hotel-policy-content1" v-if="orderInfo.hotelPolicy.fee_mandatory">
                <div><strong>{{ $t('mandatory_fees') }}</strong></div>
                <div class="ex-content2" v-html="orderInfo.hotelPolicy.fee_mandatory">
                </div>
              </div>
              <div class="hotel-policy-content1" v-if="orderInfo.hotelPolicy.fee_optional">
                <div><strong>{{ $t('optional_extras') }}</strong></div>
                <div class="ex-content2" v-html="orderInfo.hotelPolicy.fee_optional">
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="body1" v-else>
    <a-spin :spinning="initLoading" size="large">
      <div style="width: 100%;height: 500px"></div>
    </a-spin>
  </div>

  <a-modal v-model:visible="confirmCancelVisible" :maskClosable="false" :title="null" @ok="cancelBooking" :closable="false" :okText="$t('confirm_cancel')" :cancelText="$t('close')" :confirm-loading="cancelLoading">
    <a-alert type="warning" show-icon banner>
      <template #message>
        {{ $t('cancel_booking') }}
      </template>
      <template #description>
        <div v-if="isZhCn">
          该订单总价为 {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}, 取消该订单将扣取 <strong class="warning-color">{{ orderInfo.curcode }} {{ $filters.formatAmount(this.cancelled_fee, 2) }}</strong>。
        </div>
        <div v-else>
          Total price is {{ orderInfo.curcode }} {{ $filters.formatAmount(orderInfo.price, 0) }}. You will be charged <strong class="warning-color">{{ orderInfo.curcode }} {{ $filters.formatAmount(this.cancelled_fee, 2) }}</strong> once you confirm to cancel this booking.
        </div>
      </template>
    </a-alert>
  </a-modal>

  <a-modal v-model:visible="pendingVisible" :centered="true" :maskClosable="false"  :title="null" :footer="null" :closable="true" :okText="null" :cancelText="null">
    <a-spin :spinning="pendingVisible" size="large" :style="{width: '100%',margin:'20px auto'}">
    <template #tip>
      {{$t('booking_loading')}}
    </template>
  </a-spin>
  </a-modal>

</template>

<script>
import {inject} from "vue";
import dayjs from "dayjs";
import {isEmpty, getNameCnEn, getEmptyValue, getNameCn, getCancelDatetime, isRefundable, isERoom, getECancelText, isEpsPartiallyRefundable} from "@/utils/util";
import {getInfo, cancel, getStatus, getCancelFee} from "@/services/booking";
import {Modal} from "ant-design-vue";
import OrderHotelStatusTag from "@/views/booking/OrderHotelStatusTag";
import { StarFilled,QuestionCircleFilled } from '@ant-design/icons-vue';
import {AllCountries} from "@/data/country";
import {OrderHotelStatus} from "@/data/order-hotel-status";
import TicketLog from "@/views/ticket/TicketLog";
import ERateDetail from "@/views/booking/ERateDetail";

export default {
  name: "BookingDetail",
  components: { TicketLog,OrderHotelStatusTag,StarFilled,QuestionCircleFilled,ERateDetail },
  data() {
    const orderId = this.$route.params?.OrderId
    if(isEmpty(orderId))
      location.href = '/home'

    document.title = document.title.replace('-', orderId + ' -')
    getInfo(orderId, this.$store.getters["setting/getLanguage"]).then(res => {
      const resData = res.data
      if (resData.Success) {
        this.initLoading = false
        this.orderInfo = resData.Data
        this.orderInfo.PaxRoom = JSON.parse(this.orderInfo.pax_room_json)
        if(!this.orderInfo.PaxRoom.Children)
          this.orderInfo.PaxRoom.Children = []

        if(isERoom(this.orderInfo.rate_plan) && this.orderInfo?.ExtraData){
          this.EExtraData = this.orderInfo.ExtraData
          if(this.EExtraData?.AirTicketInfo)
            this.EExtraData.AirTicketInfo = JSON.parse(this.EExtraData.AirTicketInfo)
          if(this.EExtraData?.rate)
            this.EExtraData.ERateInfo = JSON.parse(this.EExtraData.rate)
          this.EOccPricing = Object.values(this.EExtraData.ERateInfo.occupancy_pricing)[0]
          this.EExtraData.rate = undefined
          this.orderInfo.ExtraData = undefined
        }

        this.pendingVisible = this.orderInfo.order_status === OrderHotelStatus.Pending || this.orderInfo.order_status === OrderHotelStatus.NewOrder || this.orderInfo.order_status === OrderHotelStatus.Locking
        this.refreshOrderStatus()
      } else {
        Modal.error({
          centered: true,
          title: this.$t('error.' + resData.Code),
        });
      }
    }).catch(error => {
      console.log(error)
    })

    const currentUser = this.$store.getters["account/getUser"]

    return {
      dayjs,
      checkInOutFormat: 'YYYY-MM-DD(ddd)',
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      selectedMenuKeys: inject('selectedMenuKeys'),
      OrderHotelStatus,
      currentUser,
      orderId,
      orderInfo: undefined,
      EExtraData: undefined,
      EOccPricing: undefined,
      initLoading: true,
      confirmCancelVisible:false,
      cancelLoading: false,
      cancelled_fee: 0,
      pendingVisible: false,
    }
  },
  beforeMount() {
    this.selectedMenuKeys = ['booking_list']
  },
  computed: {
    isFreeCancel(){
      if(!isRefundable(this.orderInfo.cancel_hour, dayjs.unix(this.orderInfo.checkin), dayjs.unix(this.orderInfo.order_date)))
        return false;

      if(dayjs().isAfter(dayjs.unix(this.orderInfo.cancel_date)))
        return false

      return true;
    }
  },
  methods: {
    isEmpty,
    getNameCnEn,
    getEmptyValue,
    getNameCn,
    getCancelDatetime,
    isRefundable,
    getECancelText,isEpsPartiallyRefundable,
    getCountryName(countryCode){
      let country = AllCountries.filter(item => item.code === countryCode)
      if(country)
        return this.isZhCn ? country[0].name_cn: country[0].name_en
      else
        return countryCode
    },
    showCancelFee(){
      this.cancelLoading = true
      getCancelFee(this.orderId).then(res => {
        this.cancelLoading = false
        const resData = res.data
        if (resData.Success) {
          this.confirmCancelVisible = true
          this.cancelled_fee = resData.Data
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
            okText: null
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
    cancelBooking(){
      this.cancelLoading = true
      cancel(this.orderId).then(res => {
        this.cancelLoading = false
        const resData = res.data
        if (resData.Success) {
          Modal.success({
            centered: true,
            title: this.$ta('cancel|success'),
            onOk: function(){
              location.reload()
            }
          });
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
            okText: null
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
    refreshOrderStatus(){
      if(this.orderInfo.order_status === OrderHotelStatus.Pending || this.orderInfo.order_status === OrderHotelStatus.NewOrder || this.orderInfo.order_status === OrderHotelStatus.Locking) {
        getStatus(this.orderId).then(res => {
          let resData = res.data
          if (resData.Success) {
            if (this.orderInfo.order_status !== resData.Data) {
              location.reload()
            } else {
              setTimeout(this.refreshOrderStatus, 5000)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
  }
}
</script>

<style scoped>
.order-detail {
  margin: 20px 0px;
}
.order-detail table {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.order-detail th {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  background-color: #FAFAFA;
}
.order-detail th strong {
  font-size: 14px;
}
.order-detail th, .order-detail td {
  padding: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.order-detail td {
  width: 25%;
  font-size: 13px;
}
.order-detail .label {
  font-size: 13px;
  color: rgba(0,0,0,0.6)
}
.order-detail1 td {
  width: 20%;
  font-size: 13px;
}


.hotel-policy-content1 {
  padding-left:10px;
  line-height: 25px;
}

.hotel-policy-content1 span {
  padding-right: 20px;
}
</style>